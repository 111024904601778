import React, { useState, useEffect } from "react";
import http from '../_api/http';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';


const MediaCoverage = () => {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL;

  useEffect(() => {
    http.get('api/imageupload/ApiImageUploadList?image_subcategory_id=17&section=Media').then((res) => {
      if (res.data.status === 200) {
        setImage(res.data.image);
        setLoading(false);
      }
    });
  }, []);
  return (
    <section className="gallery-one">
      <div className="container">
        <div className="row">
          {loading
            ? <h4>View Media Coverage Images Loading...</h4>
            :
            <>
              <PhotoProvider>

                {image.map((item, i) =>
                  <div className="col-lg-3 col-md-4" key={i}>
                    <div className="galleryPhotoImg">

                      <PhotoView key={i} src={PUBLIC_URL + "uploadimage/" + item.image}>
                        <img src={PUBLIC_URL + "uploadimage/" + item.image} alt="" />
                      </PhotoView>

                      <div className="gallary-one__content">
                        <p className="gallary-one__gallary">
                          {item.image_title}
                        </p>
                      </div>
                      <a className="gallery-one__popup img-popup">
                        <i className="kipso-icon-plus-symbol"></i>
                      </a>
                    </div>
                  </div>
                )}
              </PhotoProvider>

            </>
          }
        </div>
      </div>
    </section>
  );
};

export default MediaCoverage;
